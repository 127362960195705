<template>
	<b-row class="mt-2">
		<b-col>
			<b-card
				v-if="$can('wallet_transactions', 'update_password')"
				no-body
				class="mb-1">
				<b-card-header
					header-tag="header"
					class="p-1"
					role="tab">
					<b-button
						v-b-toggle.credentials
						block
						class="text-left font-weight-bold"
						href="#"
						variant="default">
						<h5 class="mb-0 d-inline">
							{{ translate('credentials') }}
						</h5>
						<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
						<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
					</b-button>
				</b-card-header>
				<b-collapse
					id="credentials"
					v-model="credentialsExpanded"
					accordion="credentials"
					role="tabpanel">
					<b-card-body>
						<div
							v-if="!walletPasswordLoading"
							class="table-responsive">
							<table class="table">
								<tbody>
									<editable-field
										:key="'new_password'"
										:key-value="'wallet_new_password'"
										:field-information="{ showable: '********', value: '', editable: $can('wallet_transactions', 'update_password') }"
										:field-name="translate('wallet_password')"
										:first-item="translate('wallet_password')"
										:errors="walletPasswordErrors['new_password']"
										:loading="walletPasswordLoading"
										@clearField="clearWalletPasswordErrors('new_password')"
										@editField="editWalletPassword($event, 'new_password')" />
								</tbody>
							</table>
						</div>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('data_not_found')"
							:loading="walletPasswordLoading" />
					</b-card-body>
				</b-collapse>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import EditableField from '@/components/EditableField';
import Wallets from '@/util/Wallets';
import {
	Wallets as WalletMessages, Validations, Common,
} from '@/translations';

export default {
	name: 'WalletsSettings',
	messages: [WalletMessages, Validations, Common],
	components: { EditableField },
	data() {
		return {
			alert: new this.$Alert(),
			walletPassword: new Wallets(),
			credentialsExpanded: true,
		};
	},
	computed: {
		walletPasswordLoading() {
			try {
				return this.walletPassword.data.loading;
			} catch (error) {
				return false;
			}
		},
		walletPasswordErrors: {
			get() {
				try {
					return this.walletPassword.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.walletPassword.data.errors.errors = newErrors;
			},
		},
	},
	methods: {
		editWalletPassword(fieldValue, id) {
			const trans = {
				title: this.translate('change_ewallet_password'),
				passwordTitle: this.translate('backoffice_password'),
			};

			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};

			this.alert.confirmationWithPassword(trans.title, trans.passwordTitle, options).then((password) => {
				const payload = {
					password: password.value,
				};

				payload[id] = fieldValue;
				payload[id] = fieldValue.password;
				payload[`${id}_confirmation`] = fieldValue.passwordConfirmation;

				let userId = this.$user.details().id;
				if (typeof this.$route.params.distributorId !== 'undefined') {
					userId = this.$route.params.distributorId;
				}
				this.walletPassword.updateWalletPassword(userId, payload).then(() => {
					this.alert.toast('success', this.translate('wallet_updated'));
					this.credentialsExpanded = true;
				}).catch(() => {
					let errorName = 'default_error_message';
					if (this.hasWalletPasswordError('password')) {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		clearWalletPasswordErrors(field) {
			if (field) {
				delete this.walletPasswordErrors[field];
				this.walletPasswordErrors = {};
			}
		},
		hasWalletPasswordError(field) {
			if (typeof this.walletPasswordErrors[field] !== 'undefined') { return true; }
			return false;
		},
	},
};
</script>

<style scoped>
	.table td.borderless {
		border: 0px !important;
	}

	.when-opened, .when-closed {
		float: right;
	}

	.collapsed > h5 > .when-opened,
	:not(.collapsed) > h5 > .when-closed {
		display: none;
	}
</style>
